import '../styles/Navbar.css'
import React, {useState, useEffect} from 'react'
import logo from '../navLogo.svg'

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  const toggleNav = () => {

    setToggleMenu(prevState => !prevState)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])

  return (
    <nav className='pt-2' >
      {(toggleMenu || screenWidth > 850) && (
        <>
        <div className="nav-flex">
          <div>
            <a href='#'>
              <img className="nav-logo"  src={logo}/>
            </a>
            
          </div>
          <div className="nav-li">
          <ul className="list">
            <li className="items"><a href="#" onClick={toggleNav}>Home</a></li>
            <li className="items"><a href="#about-us"  onClick={toggleNav}>About-Us</a></li>
    
            <li className="items"><a href="#products"  onClick={toggleNav}>Products</a></li>
            <li className="items"><a  href="#page-contact"  onClick={toggleNav}> Contact-us</a></li>
          </ul>
          
          
          </div>
          
        </div>
        {/* <div className="div-border"> </div> */}
      </>
      )}

      {/* <button onClick={toggleNav} className="btn">BTN</button> */}
      {
      !toggleMenu ? 
      <>
        <div className="d-flex justify-content-around align-items-center">
        <a href='#'><img className="nav-logo-res m-2" src={logo}/></a>
        <div className="btn-toogle  m-2" onClick={toggleNav}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        </div>

      </> :
     <>
       
     <div className="btn-toogle" onClick={toggleNav}>
        <a className="close"> </a>
      </div>
    </>}

    </nav>
  )
}