import '../styles/ProductCard.css'

import React from "react"
import { PopUp } from './PopUp'
export default function ProductCard(props){
    const [wantMoreInfo, changeIngo] = React.useState(false)
    const[productInfo, changeInfo] = React.useState(props.productInfo)

    const [displaProp] = React.useState( diplayProperties());
    function diplayProperties(){
        if (props.productInfo.id % 2 === 0 ){
          return {
            "productCard": {
                "backgroundColor": "lightgray",
                "flexDirection": "row-reverse"
            },
            "details" :  "left"
          }
        } else{
            return {
                "productCard": {
                    "backgroundColor": "white",
                    "flexDirection": "row"
                },
                "details" : "left"
              }
        }
    }
   
    function toogleInfo(){
        changeIngo(prevState => !prevState)
    }


    const featureList = productInfo.features.map(feature =>{
        return <li>{feature}</li>
    })
    
    const tableRows =  productInfo.table.map(row =>{
        return (
            <tr>
                <th scope="row">{row.title}</th>
                <td>{row.cotent}</td>
            </tr>
            )
           
})
    return (
        <>
        
        <div className="product-container " >
      
        {wantMoreInfo && <PopUp wantMoreInfo={wantMoreInfo} productInfo={productInfo} toogleInfo={toogleInfo} featureList={featureList} tableRows={tableRows} />}

     
        
        <div className={`product-card m-0 px-lg-5`} style={displaProp.productCard}>
        <div className="imgBx">
            <img className="product-image" src={process.env.PUBLIC_URL + '/ProductPng' + props.image}   />
        </div>
        <div className={`details text-${displaProp.details.toString()}`} >
            <div className="content">
                <h2>{productInfo.title}<br />
                    <span>{productInfo.type}</span>
                </h2>
                <span className="d-block">
                    {productInfo.discription}
                </span>

                <button onClick={toogleInfo}>More-info</button>
            </div>
        </div>
        
        </div>
        </div>
        </>
    )
}