
export default  {
    products: [
        {
            id:1,
            imageURL: "/01.png",
            title: "ATA01 | Grey ",
            type: "Tile Adhesive",
            discription: `Ashlar tile adhesive ATAO1 is a polymer modified cementitious tile adhesive. It is suitable for small and
            medium size ceramic tiles in internal areas. It is a single component adhesive which is mixed with just water for application.`,
            features: [
                `Suitable for fixing ceramic tiles on walls and floors in internal areas`,
                `Polymer modified adhesive for higher strength`,
                ` Ideal for concrete and plaster surface`,
                `Single component`,
                `Easy to use-Just add water`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Interior Wall And Floor"
                },
                {
                    title: "Surface",
                    cotent: "Concrete and plaster surface"
                },
                {
                    title: "Packaging",
                    cotent: "20Kg Bag"
                },
                {
                    title: "Color",
                    cotent: "Grey"
                },
                {
                    title: "Compliance",
                    cotent: "Type-1/C1"
                },
                {
                    title: "Coverage",
                    cotent: "50-60 Sq.Ft.Per 20kg Bag or a 3mm Bed Thickness"
                },
            ]
        },

        {
            id:2,
            imageURL: "/02.png",
            title: "ATA02 | Grey and White",
            type: "Tile Adhesive",
            discription: `Ashlar tile adhesive ATAO2 is a high strength polymer modified tile adhesive. It is suitable for small and medium size ceramic tiles, vitrified tiles and natural stones in internal as well as external areas. It is a specially designed heavy duty adhesive for external surface application for extreme climate situations.`,
            features: [
                `Suitable for fixing ceramic tiles, vitrified tiles and natural stones on walls and floors in internal and external areas`,
                `Polymer modified adhesive for higher strength`,
                `Ideal for concrete and plaster surface, brick masonry, cement terrazzo`,
                `Heavy duty, Single component`,
                `Easy to use-Just add water`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Interior Wall, Exterior Wall And Floor"
                },
                {
                    title: "Surface",
                    cotent: "Concrete and plaster surface, brick masonry, cement terrazzo"
                },
                {
                    title: "Packaging",
                    cotent: "20Kg Bag"
                },
                {
                    title: "Color",
                    cotent: "Grey and White"
                },
                {
                    title: "Compliance",
                    cotent: "Type-2/C2"
                },
                {
                    title: "Coverage",
                    cotent: "50-60 Sq.Ft.Per 20kg Bag For a 3mm Bed Thickness"
                },
            ]

        },
        {
            id:3,
            imageURL: "/03.png",
            title: "ATA03 | Grey and White",
            type: "Tile Adhesive",
            discription: `Ashlar tile adhesive ATAO3 is a specially designed adhesive for all type of ceramics tiles, vitrified tiles, semi vitrified tiles, glass mosaic tiles, precast terrazzo and natural stones on internal and external walls. `,
            features: [
                `Suitable for fixing tiles and stones on internal and external wall`,
                `Polymer modified adhesive for higher strength`,
                `High bond strength`,
                `Easy to use-Just add water`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Internal And External wall"
                },
                {
                    title: "Surface",
                    cotent: "Concrete and plaster surface,Tile on Tile Application, brick masonry, cement terrazzo"
                },
                {
                    title: "Packaging",
                    cotent: "20Kg Bag"
                },
                {
                    title: "Color",
                    cotent: "Grey and White"
                },
                {
                    title: "Compliance",
                    cotent: "Type-3/C2TE"
                },
                {
                    title: "Coverage",
                    cotent: "50-60 Sq.Ft.Per 20kg Bag For a 3mm Bed Thickness"
                },
            ]

        },
        {
            id:4,
            imageURL: "/04.png",
            title: "ATA04 | Grey and White",
            type: "Tile Adhesive",
            discription: `Ashlar tile adhesive ATAO4 is a high strength polymer-based tile adhesive especially designed for exterior application of tiles and stones on high rise elevation, façade and swimming pools. It is suitable for Large size ceramic tiles, vitrified tiles and stones.`,
            features: [
                `Suitable for fixing all types of tiles and stones on internal, external and underwater areas`,
                `Suitable for tile or stone cladding of high rise buildings`,
                `Polymer modified adhesive for higher strength`,
                `Ideal for concrete and plaster surface, tile on tile application, brick masonry, cement terrazzo`,
                `Heavy duty single component`,
                `Easy to use-Just add water`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "External wall"
                },
                {
                    title: "Surface",
                    cotent: "Concrete and plaster surface,Tile on Tile Application, brick masonry, cement terrazzo"
                },
                {
                    title: "Packaging",
                    cotent: "20Kg Bag"
                },
                {
                    title: "Color",
                    cotent: "Grey and White"
                },
                {
                    title: "Compliance",
                    cotent: "Type-3/C2TES1"
                },
                {
                    title: "Coverage",
                    cotent: "50-60 Sq.Ft.Per 20kg Bag For a 3mm Bed Thickness"
                },
            ]

        },
        {
            id:5,
            imageURL: "/05.png",
            title: "ATG01",
            type: "Tile Grout",
            discription: `Ashlar tile grout is polymer modified grout for Tile & Stone joints of width from l to 3mm on wall and floor. It is suitable for internal as well as external application. For improved strength and water resistance, mix it with Ashlar Admixture.`,
            features: [
                `Mix it with water or Ashlar admixture`,
                `Easy to apply`,
                `Available in multiple colors`,
                `Suitable for both tiles and stones `,
                `For internal and external surfaces`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Internal and External Areas"
                },
                {
                    title: "Joints",
                    cotent: "Tile as well as Stone joints up to 3mm"
                },
                {
                    title: "Packaging",
                    cotent: "1Kg Pouch"
                },
                {
                    title: "Color",
                    cotent: "Multiple"
                },
                {
                    title: "Coverage",
                    cotent: "Varies as per width of joints and dimension of tiles"
                },
            ]

        },
        {
            id:6,
            imageURL: "/06.png",
            title: "AEG01",
            type: "Epoxy Grout",
            discription: `Ashlar epoxy tile grout is a stain-free, water and chemical resistant, polymer-modified, 3 component epoxy grout for tile and stone joints of widths up to 12mm on wall and floor. It is a heavy duty product, which is suitable for internal and external use in residential, commercial as well as industrial application.`,
            features: [
                `100% solid grout suitable for joints upto 12mm`,
                `100% stainfree`,
                `Water and chemical resistant`,
                `Strong and durable`,
                `Available in multiple colors`,
                `Suitable for both tiles and stones`,
                `For internal and external surfaces`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Internal and External Areas"
                },
                {
                    title: "Joints",
                    cotent: "Tile as well as Stone Joints up to 12mm"
                },
                {
                    title: "Packaging",
                    cotent: "1Kg and 5Kg Bucket"
                },
                {
                    title: "Color",
                    cotent: "Multiple"
                },
                {
                    title: "Coverage",
                    cotent: "Varies as per width of joints and dimension of tiles"
                },
            ]

        },
        {
            id:7,
            imageURL: "/07.png",
            title: "AGA01",
            type: "Grout Admixture",
            discription: `Ashlar grout admixture is liquid Polymer additive to be used with grout for improved performance.`,
            features: [
                `Improves flexibility and water resistant`,
                `Improves adhesion strength`,
                `Suitable for any color grout`,
                `Shock and chemical resistant`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Internal and External Areas"
                },
                {
                    title: "Joints",
                    cotent: "Tile as well as Stone joints"
                },
                {
                    title: "Packaging",
                    cotent: "200ml, 400ml, 1 Litre Bottle"
                },
                {
                    title: "Coverage",
                    cotent: "Varies as per width of joints and dimension of tiles"
                },
            ]

        },
        {
            id:8,
            imageURL: "/08.png",
            title: "ABA01",
            type: "AAC Block Laying Adhesive",
            discription: `Ashlar AAC block adhesive is a factory prepared polymer modified adhesive for laying autoclave aerated concrete blocks (AAC Blocks), fly ash bricks and similar cement based blocks. 
            `,
            features: [
                `Suitable for AAC Blocks, concrete Blocks, Fly ash bricks, Cellular concrete blocks, concrete hollow blocks, cement mortar bricks`,
                `Long open time`,
                `No curing required`,
                `Very thin bed thickness required, thus improving the coverage`,
                `Fast application`,
                `Imporved adhesion between blocks`,
                `Single component, just add water`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Internal and External Areas"
                },
                {
                    title: "Surface",
                    cotent: "Cement based blocks/bricks"
                },
                {
                    title: "Packaging",
                    cotent: "30Kg Bag"
                },
                {
                    title: "Color",
                    cotent: "Grey"
                },
                {
                    title: "Coverage",
                    cotent: "Approx 100 sqft. for 30Kg Bag for a 2mm bed thickness"
                },
            ]

        },
        {
            id:9,
            imageURL: "/09.png",
            title: "AWP01",
            type: "Wall Putty",
            discription: `Ashlar wall putty is a unique formulation of high performance polymers with white cement for use on damp and dry surfaces. It is suitable for both exterior and interior applications.`,
            features: [
                `Ideal for internal as well as external walls`,
                `White, smooth and glossy finish`,
                `Ideal for new as well as old  painted walls`,
                `Protects paint from flaking`,
                `Moisture resistant`,
                `Curing is not required`
            ],
            table: [
                {
                    title: "Application Area",
                    cotent: "Internal and External plaster srufaces  areas"
                },
                {
                    title: "Packaging",
                    cotent: "30Kg Bag"
                },
                {
                    title: "Color",
                    cotent: "White"
                },
                {
                    title: "Coverage",
                    cotent: "Approx 20 Sq.ft. per kg for  2 coats of max 1.5 mm thickness"
                },
            ]

        }


        
    ]
}