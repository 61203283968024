
import '../styles/ContactUs.css'
import React, {useState, useRef} from 'react'
import emailjs from '@emailjs/browser';




export default function ContactUs(){
    const formRef= useRef();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        dummy: "",
        message: ""  
    })

    function handleChange(event){

        setFormData(prevState =>{
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }
    function submitForm(e){
        console.log(formRef.current);
        e.preventDefault();
        emailjs.sendForm('service_wdfwpvb', 'template_ho6rtzk', formRef.current, 'cIELEuD4I2U7NM9iL')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }

      
    return (
        
        <div className="contact-container container-fluid pr-0" id="page-contact">
            <div className="row">
            <h1 className="g-title mt-2 mb-0 pb-0 text-center ">Contact Us</h1>
            <div className="div-border-red mb-3" ></div>
            </div>

            <div className=" form ">
               
                <div className="form-frame" >
                 <div className="form-container">
                    <form ref={formRef} onSubmit={submitForm}>
                    <label>
                        Name
                        
                    </label>
                    <input 
                            type="text" 
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                    />
                    <br />
                    <label>
                        Phone Number
                    </label>
                    <input 
                            type="text" 
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    <br />
                    <label>
                        Email
                    </label>
                    <input 
                            type="email" 
                            name="email2"
                        />
                    <br />
                
                    <label>
                        Message
                        <textarea 
                            type="text" 
                            name="message"
                            value={formData.message}
                            style={{height:180}}
                            onChange={handleChange}
                        />
                    </label> <br />
                    <button type="submit" className="btn btn-success"> Submit</button>
                    </form>
                
                </div>  
                </div>
                <div className='contact-details text-center bg-white  fs-4'>
                    <p className='mb-0'> 
                        Manufactured & Marketed By:
                    </p>
                    <h1 className='pt-0 dark-blue'><strong>ASHLAR BUILDCHEM</strong></h1>
                    <p className='lh-sm'> 
                        <strong>Registered Office: </strong> 
                        247(NE), Scheme 4C, Jamunapuri, Jaipur-302039 (Rajasthan)
                    </p>
                    <p className='lh-base'>
                    <strong className='dark-blue'>ASHLAR CONNECT </strong> 
                     FOR EXPERT ADVICE & CONSUMER COMPLAINTS
                    </p>
                    <div className='d-flex flex-wrap border border-4 border-dark justify-content-center p-3'>
                        <div className='bottom-border'>
                            <h2 >
                            <strong><a href='tel:18003090808' className='text-decoration-none text-black'> 1800 309 0808 </a></strong>
                            </h2>
                        </div>
                        {/* <h2 className='mx-2'>
                            
                        </h2> */}
                        <div>
                            <h2> 
                              <a href='mailto:care@ashlarbuildchem.com' className='text-decoration-none text-black'>  care@ashlarbuildchem.com</a>
                            </h2>
                        </div>
                        
                    </div>
                {/* <div className="footer-img text-white text-center mb-30px mx-auto">
                        <img className=" " src={footer}  />
                    </div>
                    <div className='bg-primary p-3 mt-4 rounded text-white mb-4 text-center '>
                        <h2 >
                        <a href='tel:18003090808' className='text-decoration-none text-white'> CALL: 1800 309 0808 </a>
                        </h2>
                    </div>
                   
                    <div className='bg-danger p-3 rounded text-white mb-4 text-center'>

                        <h2>
                            Email: 
                            <a href='mailto:care@ashlarbuildchem.com' className='text-decoration-none text-white'>  care@ashlarbuildchem.com</a>
                        </h2>
                        
                    </div>*/}
                    
                </div> 
            </div>
            {/* <div className="form-info bg-black" >
                
                  
                    <div className="footer-img text-white text-center mb-30px mx-auto">
                        <img className=" " src={footer}  />
                    </div>
            </div> */}
        </div>

    )
}