import '../styles/AboutUs.css'



export default function AboutUs (){
    return (
        <>
        <div id="about-us" className="row">
            <h1 className="g-title mt-5 mb-0 pb-0 text-center text-black">About Us</h1>
            <div className="div-border-red mb-3" ></div>
        </div>
        <div className='d-flex flex-wrap justify-content-center m-7 align-items-center'>
        {/* <div className='col-md-5 '>
            <img className='slogan-about-us' src={aboutUs} /> 

        </div> */}
        <div className="about-info col-md-8 ">
            
            
            
            {/* <div className="rect-line div-2-info"></div> */}

            <p > 
            Our organization is an assembly of hardworking, experienced, dynamic, and dedicated 
            professionals, working to provide quality construction 
            chemicals.
            Currently we are manufacturing different types of tiles and 
            stone adhesives and in our next range of products we are 
            expanding to waterproofing and industrial chemicals.
            </p>
            {/* <h2> Our Vision</h2>
            <p>
            We aspire to become the most trustworthy construction
            chemical company in the market.
            <br/>
            Our genuine passion for our work, quality consciousness, 

            and agility to respond to our client’s demands ensure 
            that we earn the trust of our clients and continue to 
            become their first choice.


            </p> */}
            {/* <div className="rect-line div-1-info"></div>
            <h2> lorem </h2>
            <div className="rect-line div-2-info"></div>

            <p>
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
            </p> */}
        </div>
        </div>
        </>
    )
}