
import Home from "./components/Home"
import Navbar from "./components/Navbar"
import AboutUs from "./components/AboutUs"
import Loader from "./components/Loader"
import ContactUs from "./components/ContactUs"
import Products from "./components/Product"

import React, {useState, useEffect} from 'react'



function App() {
  const [check, changeCheck] = useState(true);

  
  useEffect(()=> {
    setTimeout(()=>{
      changeCheck(check => false)
    },5000)
  },[])

  return (
    <div className="container-fluid p-0 m-0">
      {check ? <Loader /> : <>
      <Navbar />
      <Home />
      <AboutUs />
      <Products />
      <ContactUs />
      </> }
     
    
    </div>
  );
}

export default App;
