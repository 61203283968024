import '../styles/Home.css'
import homeHero from "../home-hero.svg"

export default function Home (){

    return (
        <>
        <div className='home'> </div>
        <div className="container d-flex flex-column justify-content-centerhome">
            
            <div className="circle row d-flex justify-content-center">
                
                <div className="loader-logo">
                    <img src={homeHero} />
                    
                </div>
            </div>
            {/* <div className="row  ">
                <div className=" col col-lg-8 offset-lg-2 bg-black text-center">
                    <p className="text-footer">An ISO 9001:2015 Certified Company</p>
                </div>
            </div> */}
        </div>
        </>
       )
}