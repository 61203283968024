import React from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";

export const PopUp = (props) => {
 
  return (
    
    
        
        <div className="more-info ">
              <div className="container-lg d-flex  justify-content-end info-back">
              <div 
               role="button"
              className="position-relative text-right close-icon pt-2 mr-4 ">
                    <i onClick={props.toogleInfo} className="bg-white m-1 pb-2 p-1 rounded">
                        <AiOutlineCloseCircle />
                    </i>
                </div> <br />
              </div>
        <div className="container-lg d-flex justify-content-center info-back">
            
            <div className="row more-card pt-5 px-2">
                
                <div className="col-12 col-md-6 bg-white ">
                <h1 className='text-dark'>{props.productInfo.title}<br /></h1>
                <h2 className="px-4 text-muted"><span>{props.productInfo.type}</span></h2>
                <br/>
                <h2><strong>Features</strong></h2>
                <ul>
                    {props.featureList}
                </ul>
                
                </div>
                <div className="col-12 col-md-6 bg-white">
                <table className="table table-bordered">
                    <tbody>
                    {props.tableRows}
                    </tbody>
                    </table>
                    
                </div>

                </div>
            </div>
        </div>
    

  )
}
