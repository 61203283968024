
import Data from "../Data/Products"
import ProductCard from "./ProductCard"
import React from "react"

export default function Products(){
    const [productArr, modifyArr] = React.useState(Data.products)
   
    const productList = productArr.map((product)=>{
        return (
            <ProductCard 
                key={product.id}
                productInfo={product}
                image = {product.imageURL}
            />
            )
                
             
    })
    return (
            <>
            <div id="products" className="row">
                    <h1 className="g-title mt-5 mb-0 pb-0 text-center text-black">Products</h1>
                    <div className="div-border-red mb-3" ></div>
            </div>
            {productList}
                    
            </>
        )
}